/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
    overflow-x: hidden;
    font-family: "Roboto Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.text-muted {
    color: #777;
}

.text-primary {
    color: #fed136;
}

p {
    font-size: 14px;
    line-height: 1.75;
}

p.large {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}

a {
    color: #fed136;
}

a:hover,
a:focus,
a:active,
a.active {
    color: #fec503;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}

.img-centered {
    margin: 0 auto;
    height: 60px;
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.bg-darkest-gray {
    background-color: #222;
}

.btn-primary {
    border-color: #fed136;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #fff;
    background-color: #fed136;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    border-color: #f6bf01;
    color: #fff;
    background-color: #fec503;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: #fed136;
    background-color: #fed136;
}

.btn-primary .badge {
    color: #fed136;
    background-color: #fff;
}

.btn-xl {
    padding: 20px 40px;
    border-color: #fed136;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #fed136;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    border-color: #f6bf01;
    color: #fff;
    background-color: #fec503;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    border-color: #fed136;
    background-color: #fed136;
}

.btn-xl .badge {
    color: #fed136;
    background-color: #fff;
}

.navbar-default {
    border-color: transparent;
    background-color: #222;
}

.navbar-default .navbar-brand {
    font-family: "Kaushan Script","Helvetica Neue",Helvetica,Arial,cursive;
    color: #fed136;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
    color: #fec503;
}

.navbar-default .navbar-collapse {
    border-color: rgba(255,255,255,.02);
}

.navbar-default .navbar-toggle {
    border-color: #fed136;
    background-color: #fed136;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #fed136;
}

.navbar-default .nav li a {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
    outline: 0;
    color: #fed136;
}

.navbar-default .navbar-nav>.active>a {
    border-radius: 0;
    color: #fff;
    background-color: #fed136;
}

.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #fec503;
}

@media(min-width:768px) {
    .navbar-default {
        background-color: #222;
      /*  padding: 25px 0; */
        border: 0;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }

    .navbar-default .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }

    .navbar-default .navbar-nav>.active>a {
        border-radius: 3px;
    }

    .navbar-default.navbar-shrink {
        padding: 10px 0;
        background-color: #222;
    }

    .navbar-default.navbar-shrink .navbar-brand {
        font-size: 1.5em;
    }


    .construction{
        width: 150;
    }
}

header {
    text-align: center;
    color: #fff;
    background-attachment: scroll;
    background-image: url(../img/header-bg.jpg);
    background-position: center center;
    background-repeat: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

header .intro-text {
    padding-top: 100px;
    padding-bottom: 50px;
}

header .intro-text .intro-lead-in {
    margin-bottom: 25px;
    font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
}

header .intro-text .intro-heading {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
}

@media(min-width:768px) {
    header .intro-text {
        padding-top: 300px;
        padding-bottom: 200px;
    }

    header .intro-text .intro-lead-in {
        margin-bottom: 25px;
        font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
    }

    header .intro-text .intro-heading {
        margin-bottom: 50px;
        text-transform: uppercase;
        font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
    }
}

section {
    padding: 80px 0;
}

section h2.section-heading {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 40px;
}

section h3.section-subheading {
    margin-bottom: 55px;
    text-transform: none;
    font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}

@media(min-width:768px) {
    section {
        padding: 80px 0;
    }
}

.service-heading {
    margin: 15px 0;
    text-transform: none;
}

.text-div-service{
    min-height: 130px;
}

#portfolio .portfolio-item {
    right: 0;
    margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-caption {
    margin: 0 auto;
    padding: 10px;
    text-align: center;
    background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
    margin: 0;
    text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
    margin: 0;
    font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-style: italic;
}

#portfolio * {
    z-index: 2;
}

@media(min-width:767px) {
    #portfolio .portfolio-item {
        margin: 0 0 30px;
    }
}

.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: #f1f1f1;
}

.timeline>li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li .timeline-image {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    border: 7px solid #f1f1f1;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: #fed136;
}

/* part */
.timeline>li .timeline-image-part {
    z-index: 100;
    position: absolute;
    left: 50%;
    margin-left: -85px;
    width: 170px;
    height: 170px;
    border: 7px solid #f1f1f1;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: #fed136;
}

.timeline>li .timeline-image-part h4 {
    margin-top: 30px;
    font-size: 18px;
    line-height: 26px;
}
/*-- part */

.timeline>li .timeline-image h4 {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit;
}

.timeline .timeline-heading h4.subheading {
    text-transform: none;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
    margin-bottom: 0;
}

@media(min-width:768px) {
    .timeline:before {
        left: 50%;
    }

    .timeline>li {
        margin-bottom: 100px;
        min-height: 100px;
    }

    .timeline>li .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
    }

    .timeline>li .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
    }
}

@media(min-width:992px) {
    .timeline>li {
        min-height: 150px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px;
    }

    .timeline>li .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 26px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px;
    }
}

@media(min-width:1200px) {
    .timeline>li {
        min-height: 170px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px 100px;
    }

    .timeline>li .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 20px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px;
    }
}

.team-member {
    margin-bottom: 50px;
    text-align: center;
}

.team-member img {
    margin: 0 auto;
    border: 7px solid #fff;
}

.team-member h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
}

.team-member p {
    margin-top: 0;
}

aside.clients img {
    margin: 50px auto;
}

section#contact {
    background-color: #222;
    background-image: url(../img/map-image.png);
    background-position: center;
    background-repeat: no-repeat;
}

section#contact .section-heading {
    color: #fff;
}

section#contact .form-group {
    margin-bottom: 15px;
    height: 70px
}

section#contact .form-group input,
section#contact .form-group textarea {
    padding: 20px;
}

section#contact .form-group input.form-control {
    height: auto;
}

section#contact .form-group textarea.form-control {
    height: 236px;
}

section#contact .form-control:focus {
    border-color: #fed136;
    box-shadow: none;
}

section#contact::-webkit-input-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#contact:-moz-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#contact::-moz-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#contact:-ms-input-placeholder {
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

section#contact .text-danger {
  /*  color: #e74c3c;*/
}

footer {
    padding: 25px 0;
    text-align: center;
}

footer span.copyright {
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 40px;
}

footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 40px;
}

.social-buttons {
    margin-bottom: 0;
    display: block;
    width: 40px;
    height: 40px;
    outline: 0;
    color: #fff;
    background-color: #222;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0;
}

.portfolio-modal .modal-content {
    padding: 100px 0;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    text-align: center;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.portfolio-modal .modal-content h2 {
    margin-bottom: 15px;
    font-size: 3em;
}

.portfolio-modal .modal-content .item-intro {
    font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-style: italic;
}

.portfolio-modal .modal-content ul.list-inline {
    margin-top: 0;
    margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
    margin-bottom: 30px;
}

.portfolio-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer;
}

.portfolio-modal .close-modal:hover {
    opacity: .3;
}

.portfolio-modal .close-modal .lr {
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    background-color: #222;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.portfolio-modal .close-modal .lr .rl {
    z-index: 1052;
    width: 1px;
    height: 75px;
    background-color: #222;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.portfolio-modal .modal-backdrop {
    display: none;
    opacity: 0;
}

::-moz-selection {
    text-shadow: none;
    background: #fed136;
}

::selection {
    text-shadow: none;
    background: #fed136;
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

body {
    webkit-tap-highlight-color: #fed136;
}


/* Dropdownn-menu for lang */

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    color: #fff;
    background-color: #222;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 0px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #222;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.navbar-default .nav li a:hover, .navbar-default .nav li a:focus {
    outline: 0;
    color: #fed136;
    background-color: #222;
}

@media (max-width: 767px){
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus, .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #fed136;
        background-color: transparent;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus, .navbar-default .navbar-nav .open .dropdown-menu>li>a{
        color: #fff;
        background-color: transparent;
    }
}

/*  datepicker */
.input-daterange input {
    text-align: left;
}

section#contact .form-group input.form-control {
    height: auto;
    border-radius: 4px;
}

.datepicker thead tr:first-child th, .datepicker tfoot tr th {
    color: #fed136;
}

.datepicker table tr td.day, .datepicker table tr td.day.focused {
    color: #fed136;
}

.datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
    color: white;
    background: #fed136;
    cursor: pointer;
}

.datepicker table tr td.disabled.day:hover, .datepicker table tr td.disabled.day.focused {
    color: #999999;
    background: none;
    cursor: default;
}

.datepicker .active.selected.day{
    color: white;
    background: #fed136;
}

.datepicker .active.selected.day:hover{
    color: white;
    background: #fed136;
}

.datepicker .month.active{
    color: white;
    background: #fed136;
}

.datepicker .month.active :hover{
    color: white;
    background: #fed136;
}

.datepicker .month {
    color: #fed136;
}

.datepicker .month:hover {
    color: white;
    background: #fed136;
}

.datepicker .dow{
    color:#fed136;
}

.datepicker .disabled.day {
    color: #999999;
}

.datepicker thead tr:first-child th:hover, .datepicker tfoot tr th:hover {
    background: #fed136;
    color:white;
}

.datepicker .datepicker-switch{
    color:#fed136;
}

.datepicker .datepicker-switch:hover{
    background: #fed136;
    color:white;
}

.datepicker .next{
    color:#fed136;
}

.datepicker .prev{
    color:#fed136;
}

.datepicker .next:hover{
    background: #fed136;
    color:white;
}

.datepicker .prev:hover{
    background: #fed136;
    color:white;
}

/* registration h3 */
.registration-h3{
    color: #CCC;
}

.fields-requered{
    padding-top: 8px;
}

/* button send btn-m */
.btn-m {
    padding: 15px 40px;
    border-color: #fed136;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #fed136;
}

.btn-m:hover,
.btn-m:focus,
.btn-m:active,
.btn-m.active,
.open .dropdown-toggle.btn-m {
    border-color: #f6bf01;
    color: #fff;
    background-color: #fec503;
}

.btn-m:active,
.btn-m.active,
.open .dropdown-toggle.btn-m {
    background-image: none;
}

.btn-m.disabled,
.btn-m[disabled],
fieldset[disabled] .btn-m,
.btn-m.disabled:hover,
.btn-m[disabled]:hover,
fieldset[disabled] .btn-m:hover,
.btn-m.disabled:focus,
.btn-m[disabled]:focus,
fieldset[disabled] .btn-m:focus,
.btn-m.disabled:active,
.btn-m[disabled]:active,
fieldset[disabled] .btn-m:active,
.btn-m.disabled.active,
.btn-m[disabled].active,
fieldset[disabled] .btn-m.active {
    border-color: #fed136;
    background-color: #fed136;
}

.btn-m .badge {
    color: #fed136;
    background-color: #fff;
}


/* button send btn-s */
.btn-s {
    padding: 5px;
    margin-bottom: 25px;
    border-color: #fed136;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #fff;
    background-color: #fed136;
    font-weight: bold;
}

.btn-s:hover,
.btn-s:focus,
.btn-s:active,
.btn-s.active,
.open .dropdown-toggle.btn-s {
    border-color: #f6bf01;
    color: #fff;
    background-color: #fec503;
}

.btn-s:active,
.btn-s.active,
.open .dropdown-toggle.btn-s {
    background-image: none;
}

.btn-s.disabled,
.btn-s[disabled],
fieldset[disabled] .btn-s,
.btn-s.disabled:hover,
.btn-s[disabled]:hover,
fieldset[disabled] .btn-s:hover,
.btn-s.disabled:focus,
.btn-s[disabled]:focus,
fieldset[disabled] .btn-s:focus,
.btn-s.disabled:active,
.btn-s[disabled]:active,
fieldset[disabled] .btn-s:active,
.btn-s.disabled.active,
.btn-s[disabled].active,
fieldset[disabled] .btn-s.active {
    border-color: #fed136;
    background-color: #fed136;
}

.btn-s .badge {
    color: #fed136;
    background-color: #fff;
}

/* friend */
.fiends{
    background-color: #ffffff;
    padding-bottom: 5px;
    padding-top: 5px;
}

/* error */
.error{
    display: none;
    color: #FED136;
}

#success {
    display: none;
    color: #F9F9F9;
    font-size: 20px;
}

/* google map and youtube*/
.google-map>iframe{
    width:600px;
    height:450px;
}

.you-tube>iframe {
    width:100%;
    height:356px;
}

@media (max-width: 767px) {
    .google-map>iframe {
        width: 100%;
        height: auto;
    }

    .you-tube>iframe {
        width: 100%;
        height: auto;
    }
}

/* modal css */
.modal-section{
    margin-top: 50px;
}

.modal-table{
    text-align: left;
    width: auto;
    margin: 0 auto;
}

.modal-table td{
    text-align: left;
}

.modal-section h4{
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    text-transform: none;
}

.modal-section .form-group {
    margin-bottom: 15px;
    height: 40px;
}


#gift_success {
    display: none;
    color: #337AB7;
    font-size: 20px;
}

/* modal select */
.bootstrap-select.btn-group .dropdown-menu{
    background-color: white;
}


/* gift card */

.single-detail-icon {
    width: 40px;
    height: 40px;
    border: 1px solid #3498DB;
    color: #3498DB;
    text-align: center;
}

.underline {
   margin: auto;
   margin-top: 70px;
   border-top: 1px solid;
   width: 300px;
}

.gift-body{
    margin: auto;
    margin-top: 30px;
    text-align: center;
}

.gift-body-left{
    text-align: left;
    margin-top: 10px;
}

.gift-body-left p {
    font-size: 12px;
    line-height: 1.75;
}

.gift-body-left p {
    margin: 0 0 0px;
}

.inline {
    display: inline-block;
    margin-left: 15px;
}

.pl40{
    padding-left: 40px;
}

.container-btn{
    margin: 50px;
}

.container-gift{
    width: 595px;
    height: 642px;
}

.gift-img{
    width: 300px;
    margin: 0 auto;
}

.gift-head {
    text-align: center;
    margin: 0 auto;
}

.gift-head>h2{
    text-transform: none;
}


/*---*/

.team-member a {
    text-decoration: none;
    color: inherit;
}


/* image modal */
.modal-section .img-centered {
    margin: 0 auto;
    height: 200px;
}
